import React from 'react';

const PanelesSolares = () => {
    return (
        <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
            <iframe
                src="https://uni001eu5.fusionsolar.huawei.com/pvmswebsite/nologin/assets/build/cloud.html#/kiosk?kk=2qzsNwNQLqopIB6LwsMi0hqlJMCPq049"
                title="Panel Solar Bionorica"
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none'
                }}
            />
        </div>
    );
};

export default PanelesSolares;

